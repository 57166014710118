// // /* eslint-disable no-unused-vars */
import styles from '../css/main.scss'

// utils
// import { TweenMax } from './utils/tweenmax.min.js'
import { TweenMax, TimelineMax, Expo} from "gsap"
// import imagesLoaded from './utils/imagesloaded.pkgd.min.js'
import { preloader } from './utils/preloader.js'
// import { LottieInteractive } from 'lottie-interactive'

// img
// import detuut from '../img/detuut.png'
import dester from '../img/dester.png'
import superbuurt from '../img/superbuurt.png'
// import talkie from '../img/talkie.png'
// import fisheye from '../img/fisheye.jpg'
import download from '../img/download.svg'
import downloadW from '../img/download-white.svg'

// elements
let body = document.querySelector('body');
let text = document.querySelectorAll("h1, p, h2, a");
let lines = document.querySelectorAll(".work-item h2");
let outlineText = document.querySelectorAll("h1 span");
let footerCta = document.querySelector(".footer-cta");
let arrowWhite = document.querySelector(".arrow-white");
let arrowBlack = document.querySelector(".arrow-black");
let downloadWhite = document.querySelector(".download-white");
let downloadBlack = document.querySelector(".download-black");

let desterContainer = document.getElementById("dester");
let detuutContainer = document.getElementById("detuut");
let superbuurtContainer = document.getElementById("superbuurt");
let talkieContainer = document.getElementById("talkie");
// let fisheyeContainer = document.getElementById("fisheye");
let iedereenmeeContainer = document.getElementById("iedereenmee");
let gillianContainer = document.getElementById("gillian");


// TODO: refine animation header
// import lottieWeb from 'lottie-web';

// var container = document.getElementById('anim');
// var state = 'pause';

// var animation = lottieWeb.loadAnimation({
//     container: container,
//     path: 'https://assets5.lottiefiles.com/packages/lf20_cy8OS8.json',
//     renderer: 'svg',
//     loop: true,
//     autoplay: true,
//     name: "Demo Animation",
//     rendererSettings: {
//         className: 'some-css-class-name',
//         id: 'some-id',
//     },
// });

// animation.goToAndStop(14, true);

// container.addEventListener('hover', () => {
//     if (state === 'play') {
//         animation.playSegments([14, 27], true);
//         state = 'pause';
//     } else {
//         animation.playSegments([0, 14], true);
//         state = 'play';
//     }
// });


// preload the images set as data attrs in the menu items
preloader('.menu__item').then(() => {

    // LottieInteractive.create({
    //     mode: 'scroll',
    //     player: '#firstLottie',
    //     actions: [
    //         {
    //             visibility: [0, 1],
    //             type: 'seek',
    //             frames: [0, 100],
    //         },
    //     ],
    // });

    // line animation
    let path = document.querySelector(".line-drawing");
    let length = path.getTotalLength();
    path.style.strokeDasharray = length + ' ' + length;
    path.style.strokeDashoffset = length;

    // load animation
    setTimeout(function () {
        document.getElementById("wrap").classList.remove('loader');
        document.getElementById("wrap").classList.add('loaded');
    }, 500);


    // load images
    // var detuutImg = document.getElementById('detuutImg');
    // detuutImg.src = detuut;
    // var desterImg = document.getElementById('desterImg');
    // desterImg.src = dester;
    // var superbuurtImg = document.getElementById('superbuurtImg');
    // superbuurtImg.src = superbuurt;
    // var talkieImg = document.getElementById('talkieImg');
    // talkieImg.src = talkie;
    // var fisheyeImg = document.getElementById('fisheyeImg');
    // fisheyeImg.src = fisheye;
    var downloadImg = document.getElementById('download');
    downloadImg.src = download;
    var downloadWImg = document.getElementById('downloadW');
    downloadWImg.src = downloadW;
    
    if (!isMobile()) {
        // work animation
        [...document.querySelectorAll('[data-fx="3"] > a, [data-fx="3"] > div')].forEach(link => new ImageHover(link));
    } else {
        // work cards
        desterContainer.style.backgroundColor = '#FFFFFF'
        superbuurtContainer.style.backgroundColor = '#FFFFFF'
        detuutContainer.style.backgroundColor = '#FFFFFF'
        talkieContainer.style.backgroundColor = '#FFFFFF'
        // fisheyeContainer.style.backgroundColor = '#FFFFFF'
        iedereenmeeContainer.style.backgroundColor = '#FFFFFF'
        gillianContainer.style.backgroundColor = '#FFFFFF'
    }
});

// const mapNumber = (X, A, B, C, D) => (X - A) * (D - C) / (B - A) + C;
const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    }
    else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    return { x: posx, y: posy }
}
    // Generate a random float.
    // const getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

function isMobile () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

class ImageHover {

    constructor(el) {
        this.DOM = { el: el };
        this.DOM.reveal = document.createElement('div');
        this.DOM.reveal.className = 'hover-reveal';
        this.DOM.reveal.style.overflow = 'hidden';

        // video or image
        if (this.DOM.el.dataset.type) this.DOM.reveal.innerHTML = `<div class="hover-reveal__inner"><video playsinline muted loop class="hover-reveal__img"><source src="${this.DOM.el.dataset.img}" type="video/mp4"></video></div>`;
        else this.DOM.reveal.innerHTML = `<div class="hover-reveal__inner"><div class="hover-reveal__img" style="background-image:url(${this.DOM.el.dataset.img});"></div></div>`;
        
        this.DOM.el.appendChild(this.DOM.reveal);
        this.DOM.revealInner = this.DOM.reveal.querySelector('.hover-reveal__inner');
        this.DOM.revealInner.style.overflow = 'hidden';
        this.DOM.revealImg = this.DOM.revealInner.querySelector('.hover-reveal__img');
        this.DOM.body = document.querySelector('html');
        this.initEvents();
    }

    initEvents () {
        this.positionElement = (ev) => {
            const mousePos = getMousePos(ev);
            const docScrolls = {
                left: document.body.scrollLeft + document.documentElement.scrollLeft,
                top: document.body.scrollTop + document.documentElement.scrollTop
            };
            this.DOM.reveal.style.top = `${mousePos.y - 125 - docScrolls.top}px`;
            this.DOM.reveal.style.left = `${mousePos.x + 350 - docScrolls.left}px`;

        };
        this.mouseenterFn = (ev) => {
            // change css page
            body.style.transition = 'color .4s ease-in, background-color .2s ease-in'
            body.style.backgroundColor = `${this.DOM.el.dataset.color}`
            text.forEach(el => { el.style.color = "white"; el.style.borderColor = "white"; });
            lines.forEach(el => { el.style.borderColor = "white"; });
            outlineText.forEach(el => { el.style.webkitTextStrokeColor = "white"; });
            footerCta.style.borderColor = "white";
            arrowWhite.style.display = "block";
            arrowBlack.style.display = "none";
            downloadWhite.style.display = "block";
            downloadBlack.style.display = "none";
            // hover animation
            this.positionElement(ev);
            this.showImage();
        };
        this.mousemoveFn = ev => requestAnimationFrame(() => {
            this.positionElement(ev);
        });
        this.mouseleaveFn = () => {
            // change css page
            body.style.backgroundColor = ""
            text.forEach(el => { el.style.color = ""; el.style.borderColor = "";});
            lines.forEach(el => { el.style.borderTop = ""; });
            outlineText.forEach(el => { el.style.webkitTextStrokeColor = ""; });
            footerCta.style.borderColor = "";
            arrowWhite.style.display = "none";
            arrowBlack.style.display = "block";
            downloadWhite.style.display = "none";
            downloadBlack.style.display = "block";

            // hover animation
            this.hideImage();
        };

        this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
        this.DOM.el.addEventListener('mousemove', this.mousemoveFn);
        this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
    }
    showImage () {
        TweenMax.killTweensOf(this.DOM.revealInner);
        TweenMax.killTweensOf(this.DOM.revealImg);

        this.tl = new TimelineMax({
            onStart: () => {
                this.DOM.reveal.style.opacity = 1;
                TweenMax.set(this.DOM.el, { zIndex: 5 });
            }
        })
            .add('begin')
            .set([this.DOM.revealInner, this.DOM.revealImg], { transformOrigin: '100% 100%' })
            .add(new TweenMax(this.DOM.revealInner, 0.4, {
                ease: Expo.easeOut,
                startAt: { x: '50%', y: '120%', rotation: 50 },
                x: '0%',
                y: '0%',
                rotation: 0
            }), 'begin')
            .add(new TweenMax(this.DOM.revealImg, 0.4, {
                ease: Expo.easeOut,
                startAt: { x: '-50%', y: '-120%', rotation: -50 },
                x: '0%',
                y: '0%',
                rotation: 0
            }), 'begin')
            .add(new TweenMax(this.DOM.revealImg, 0.7, {
                ease: Expo.easeOut,
                startAt: { scale: 2 },
                scale: 1
            }), 'begin');
        
        // if video, video start
        let t = this
        t.DOM.el.querySelectorAll('.hover-reveal__img')[0].currentTime = 0;
        setTimeout(function () {
            if (t.DOM.el.dataset.type) {
                t.DOM.el.querySelectorAll('.hover-reveal__img')[0].play();
            }
        }, 500);

    }
    hideImage () {
        TweenMax.killTweensOf(this.DOM.revealInner);
        TweenMax.killTweensOf(this.DOM.revealImg);

        this.tl = new TimelineMax({
            onStart: () => {
                TweenMax.set(this.DOM.el, { zIndex: 0 });
            },
            onComplete: () => {
                TweenMax.set(this.DOM.el, { zIndex: '' });
                TweenMax.set(this.DOM.reveal, { opacity: 0 });
            }
        })
            .add('begin')
            .add(new TweenMax(this.DOM.revealInner, 0.6, {
                ease: Expo.easeOut,
                y: '-120%',
                rotation: -5
            }), 'begin')
            .add(new TweenMax(this.DOM.revealImg, 0.6, {
                ease: Expo.easeOut,
                y: '120%',
                rotation: 5,
                scale: 1.2
            }), 'begin')
    }
}